import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loadings from "./Loading";

const Message = () => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  // Verify Login user or not
  const Auth = async () => {
    try {
      const res = await fetch("/root", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      await res.json();
    } catch (err) {
      navigate("/root", { replace: true });
      console.log(err);
    }
  };
  useEffect(() => {
    Auth();
  }, []);

  //GET ALL CONTACT MESSAGE
  const [messageData, setMessageData] = useState([]);

  const GetContactData = async () => {
    try {
      const res = await fetch("/message", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      if (res.status === 404) {
        console.log("Server Error Found");
      } else {
        setMessageData(data);
        setIsLoading(false);
        return data;
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    GetContactData();
  }, []);

  //Delete Dessage
  const handleDelete = async (_id) => {
    try {
      const res = await fetch("/message/" + `/${_id}`, {
        method: "DELETE",
      });
      await res.json();
      if (res.status === 201) {
        GetContactData();
        window.alert("Delete Successfull");
      } else {
        window.alert("Delete not successfull");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      {isLoading && <Loadings />}

      <div className="bg-gradient-to-r from-indigo-100 via-purple-300 to-pink-100 h-full">
        <div className="flex gap-3 p-3 md:p-10 flex-wrap-reverse flex-col-reverse md:mx-20">
          {messageData &&
            messageData.map((data) => {
              const { _id, name, email, address, mobile, message, date } = data;
              return (
                <div key={_id} className="shadow-xl p-5 rounded-md">
                  <div className="flex  justify-between items-center font-bold text-orange-500 text-lg ">
                    <h2>{name}</h2>
                    <h2 className="font-mono">+880{mobile}</h2>
                  </div>
                  <h2 className="font-normal">{email}</h2>
                  <div className="flex  justify-between items-center font-normal text-gray-700">
                    <h2>{address}</h2>
                  </div>
                  <h2>{date}</h2>
                  <div className="py-4 px-1  my-2">
                    <h2 className="underline font-base text-base text-justify">
                      {message}
                    </h2>
                  </div>
                  <div className="flex justify-center items-center mt-4 ">
                    <button
                      className="px-10 text-white font-bold py-3 bg-orange-600 hover:bg-red-700 transition duration-200 rounded shadow"
                      onClick={() => {
                        handleDelete(_id);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default Message;

import { useEffect, useState } from "react";
import axios from "axios";

function NurseData() {
  const [handleFile, setFile] = useState();
  const [name, setName] = useState();
  const handleUpload = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("file", handleFile);
  };
  const [datas, setDatas] = useState([]);
  useEffect(() => {
    axios
      .get("http://localhost:5000/api/getfile")
      .then((res) => {
        setDatas(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <div className="text-center">
      <h1 className="text-2xl font-bold text-gray-600 p-10">Image Upload</h1>
      <div className="mx-20">
        <input
          className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none border-b-2"
          type="text"
          placeholder="Jane Doe"
          name="name"
          onChange={(e) => setName(e.target.value)}
        />
        <label
          className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300"
          htmlFor="file_input"
        >
          Upload file
        </label>
        <input
          className="block w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
          aria-describedby="file_input_help"
          id="file_input"
          type="file"
          onChange={(e) => {
            setFile(e.target.files);
          }}
        />
        <p
          className="mt-1 text-sm text-gray-500 dark:text-gray-300"
          id="file_input_help"
        >
          PNG, JPG or JPEG (MAX. 800x400px).
        </p>
        <div className="flex justify-center items-center mt-5">
          <button
            className="text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2"
            onClick={handleUpload}
          >
            Upload
          </button>
        </div>
      </div>
      {datas &&
        datas.map((data) => {
          return (
            <div key={data._id}>
              <h3>{data.name}</h3>
              <img
                src={`http://localhost:5000/${data.filePath}`}
                alt="MOM"
                width="350px"
              />
            </div>
          );
        })}
    </div>
  );
}

export default NurseData;

import React from "react";
import Datas from "./Data.json";

const HomeAbout = () => {
  return (
    <div>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 p-4 ring-1 ring-gray-300 m-4">
        {Datas &&
          Datas.map((data) => {
            const { id, dep_name, desc, image } = data;
            return (
              <div key={id} className="ring-1 m-4 ring-gray-200 p-4">
                <div>
                  <div>
                    <h2 className="text-center font-bold text-lg pb-3">
                      {dep_name}
                    </h2>
                    <h2 className="text-gray-600 hover:text-black transition duration-300">
                      {desc}
                    </h2>
                  </div>
                  <img src={image} alt="" />
                </div>
              </div>
            );
          })}
      </div>
      <div className="w-full"></div>
    </div>
  );
};

export default HomeAbout;

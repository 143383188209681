import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { RiDeleteBin5Fill } from "react-icons/ri";

const BedDetails = () => {
  const [userdata, setUserData] = useState();
  const navigate = useNavigate();
  const [bedDetils, setBedDetails] = useState({
    ccutb: "",
    ccufb: "",
    pccutb: "",
    pccufb: "",
  });

  const { ccutb, ccufb, pccutb, pccufb } = bedDetils;

  const handleInput = (e) => {
    const name = e.target.name;
    setBedDetails({ ...bedDetils, [name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (ccutb < ccufb) {
      window.alert("Enter correct value");
    } else {
      const res = await fetch("/bed", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ccutb, ccufb, pccutb, pccufb }),
      });
      await res.json();
      if (res.status === 404) {
        window.alert("Filed select option");
      } else {
        BedData();
        window.alert("Details submit successful");
        setBedDetails({
          ...bedDetils,
          ccutb: "",
          ccufb: "",
          pccutb: "",
          pccufb: "",
        });
      }
    }
  };

  const Auth = async () => {
    try {
      const res = await fetch("/root", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      const data = await res.json();

      if (!res.status === 200) {
        console.log("Response not working...");
      } else {
        setUserData(data);
      }
    } catch (err) {
      navigate("/root", { replace: true });
      console.log(err);
    }
  };

  useEffect(() => {
    Auth();
  }, []);

  // GET Data from Database
  const [bedData, setBedData] = useState([]);
  const BedData = async () => {
    const res = await fetch("/bed", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const data = await res.json();
    if (res.status === 200) {
      setBedData(data);
    } else if (res.status === 404) {
      console.log("Server error");
    }
  };
  useEffect(() => {
    BedData();
  }, []);

  const handleDelete = async (_id) => {
    try {
      const res = await fetch("/bed" + `/${_id}`, {
        method: "DELETE",
      });

      await res.json();

      if (res.status === 201) {
        BedData();
        window.alert("Delete Successfull");
      } else {
        window.alert("Delete not successfull");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="bg-gradient-to-r from-indigo-100 via-purple-100 to-pink-100 ">
      <h2 className="absolute top-36  md:top-44 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-base font-bold md:text-2xl text-gray-700">
        Please add today bed details
      </h2>
      <form
        method="POST"
        className="flex justify-center items-center h-screen flex-col"
        onSubmit={handleSubmit}
      >
        <div className=" shadow-xl rounded-lg p-7 md:w-1/3 justify-center items-center flex flex-col space-y-3">
          <div className="flex space-x-10">
            <div>
              <label htmlFor="" className="font-bold text-gray-600">
                CCU Bed
              </label>
              <select
                name="ccutb"
                className="block border border-grey-light p-3 rounded mb-4 w-32"
                id="ccuTotalBed"
                value={ccutb}
                onChange={handleInput}
                required
              >
                <option>Select</option>

                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
              </select>
            </div>

            <div>
              <label htmlFor="" className="font-bold text-gray-600">
                Free Bed
              </label>
              <select
                name="ccufb"
                className="block border border-grey-light w-32 p-3 rounded mb-4"
                id="ccuFreeBed"
                required
                value={ccufb}
                onChange={handleInput}
              >
                <option>Select</option>
                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
              </select>
            </div>
          </div>
          <div className="flex space-x-10">
            <div>
              <label htmlFor="" className="font-bold text-gray-600">
                PCCU Bed
              </label>
              <select
                name="pccutb"
                className="block border border-grey-light w-32 p-3 rounded mb-4"
                id="pccuTotalBed"
                required
                value={pccutb}
                onChange={handleInput}
              >
                <option>Select</option>

                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
              </select>
            </div>
            <div>
              <label htmlFor="" className="font-bold text-gray-600">
                Free Bed
              </label>
              <select
                name="pccufb"
                className="block border border-grey-light w-32 p-3 rounded mb-4"
                id="pccuFreeBed"
                required
                value={pccufb}
                onChange={handleInput}
              >
                <option>Select</option>

                <option value="0">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option>
                <option value="15">15</option>
                <option value="16">16</option>
                <option value="17">17</option>
                <option value="18">18</option>
                <option value="19">19</option>
                <option value="20">20</option>
              </select>
            </div>
          </div>
          <div>
            <button className="px-5 py-2 bg-indigo-500 text-white rounded-3xl shadow mt-3 font-semibold hover:bg-indigo-600 tranistion duration-200">
              Submit
            </button>
          </div>
        </div>
      </form>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:p-10 p-4 bg-gradient-to-r from-indigo-100 via-purple-100 to-pink-100">
        {bedData &&
          bedData.map((data) => {
            const { _id, ccutb, ccufb, pccutb, pccufb, date } = data;
            return (
              <div key={_id} className="p-5 shadow-xl flex justify-between">
                <div className="text-lg">
                  <h1 className="font-bold capitalize md:text-lg">{date}</h1>
                  <h1>CCU Total : {ccutb}</h1>
                  <h1>CCU Free : {ccufb}</h1>
                  <h1>PCCU Total : {pccutb}</h1>
                  <h1>PCCU Free : {pccufb}</h1>
                </div>
                <button
                  className="font-bold text-2xl hover:text-red-700 transition-all duration-200"
                  onClick={() => {
                    handleDelete(_id);
                  }}
                >
                  <RiDeleteBin5Fill />
                </button>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default BedDetails;

import React, { useEffect, useState } from "react";
import "./gallery.css";
import axios from "axios";

const Gallery = () => {
  const [model, setModel] = useState(false);
  const [tempImg, setTempImg] = useState("");
  const [images, setImages] = useState([]);
  const photoClick = (image) => {
    setTempImg(image);
    setModel(true);
  };

  const getImage = () => {
    axios
      .get("/gallery")
      .then((res) => {
        setImages(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  useEffect(() => {
    getImage();
  }, []);
  return (
    <div className="m-4">
      <h2 className="text-center md:m-10 m-3 text-xl md:text-2xl font-bold text-gray-700">
        Cardiology Gallery
      </h2>
      <div className={model ? "model open" : "model"}>
        <img
          onClick={() => {
            setModel(false);
          }}
          className="cursor-not-allowed"
          src={tempImg}
          alt=""
        />
        <div
          className="absolute font-extrabold text-white top-10 right-10 text-2xl cursor-pointer"
          onClick={() => {
            setModel(false);
          }}
        >
          X
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 justify-center items-center">
        {images &&
          images.map((img) => {
            const { _id, filePath } = img;
            return (
              <div
                key={_id}
                onClick={() => {
                  photoClick(filePath);
                }}
              >
                <img
                  className="p-2 shadow cursor-pointer"
                  src={filePath}
                  alt=""
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default Gallery;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loadings from "./Loading";

const BNotice = () => {
  const [notice, setNotice] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const Auth = async () => {
    try {
      const res = await fetch("/root", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      await res.json();
    } catch (err) {
      console.log("You are not a root user");
      navigate("/root", { replace: true });
    }
  };

  const GetNoticeData = async () => {
    const res = await fetch("/notice", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const data = await res.json();
    if (res.status === 404) {
      console.log("data not get");
    } else {
      setNotice(data);
      setIsLoading(false);
      return data;
    }
  };
  useEffect(() => {
    GetNoticeData();
  }, []);

  useEffect(() => {
    Auth();
  }, []);

  // Delete Notice
  const handleDelete = async (_id) => {
    axios
      .delete("/notice" + `/${_id}`)
      .then(() => {
        GetNoticeData();
        window.alert("Notice Delete Successfull");
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  return (
    <>
      {isLoading && <Loadings />}
      <div className="flex flex-col-reverse bg-gradient-to-r from-indigo-100 via-purple-300 to-pink-100 md:p-10 p-3">
        {notice &&
          notice.map((data) => {
            const { _id, name, designation, message, date, filePath } = data;
            return (
              <div key={_id}>
                <div className="bg-white p-5 m-5 shadow-md ring-1 ring-gray-300 divide-y-2 space-y-4 flex flex-col justify-center">
                  <div className="flex justify-between">
                    <div>
                      <h2 className="uppercase text-gray-800 font-bold">
                        {name}
                      </h2>
                      <h2 className="font-light text-gray-500">
                        {designation}
                      </h2>
                      <h2 className="font-light text-gray-500">{date}</h2>
                    </div>
                    <div>
                      <a
                        className="px-5 md:px-10 text-white font-bold py-3 bg-orange-600 hover:bg-red-700 transition duration-200 rounded shadow inline-block"
                        href={filePath}
                        download
                      >
                        PDF
                      </a>
                    </div>
                  </div>
                  <h2 className="w-full h-44 overflow-y-auto py-5">
                    {message}
                  </h2>
                  <button
                    className="px-5 md:px-10 text-white font-bold py-3 bg-orange-600 hover:bg-red-700 transition duration-200 rounded shadow flex justify-center items-center "
                    onClick={() => {
                      handleDelete(_id);
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default BNotice;

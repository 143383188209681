import React, { createContext, useReducer } from "react";
import { Route, Routes } from "react-router-dom";
import DoctorSingup from "../../components/DoctorSingup";
import Message from "../../components/Message";
import NurseSingup from "../../components/NurseSingup";
import Login from "../Login";
import BedDetails from "../../components/BedDetails";
import Dlogin from "../../components/Dlogin";
import Notice from "../../components/Notice";
import NoticeForm from "../../components/NoticeForm";
import ContactForm from "../ContactForm";
import BedView from "../../components/BedView";
import BNotice from "../../components/BNotice";

import Homepages from "../../components/HomePage/Homepages";
import ImageUpload from "../../components/ImageUpload";
import DoctorData from "../../components/HomePage/DoctorData";
import HomeAbout from "../../components/Abouts/HomeAbout";
import Error from "../../components/Error";
import HomeEmploys from "../../components/Employ/HomeEmploys";
import Employ from "../../components/Employ/Employ";
import Gallery from "../../components/Gallery/Gallery";
import { reducer, initailstate } from "../../Reducer/reducer";
import { dreducer, initailstates } from "../../Reducer/dreducer";
import Logout from "../../components/Logout";
import RenderNav from "./NavBar";
import Footer from "../../components/Footer";
import NurseData from "../NurseData";
import Bimage from "../../components/Bimage";

export const UserContext = createContext();
const Routess = () => {
  return (
    <Routes>
      {/* frontend */}
      <Route path="/" element={<Homepages />} />
      <Route path="/about" element={<HomeAbout />} />
      <Route path="*" element={<Error />} />
      {/* <Route path="imageupload" element={<ImageUpload />} /> */}
      <Route path="/HomeEmploys" element={<HomeEmploys />} />
      <Route path="/HomeEmploys/:name" element={<Employ />} />
      <Route path="/contact" element={<ContactForm />} />
      <Route path="/notice" element={<Notice />} />
      <Route path="/gallery" element={<Gallery />} />
      <Route path="/nursedata" element={<NurseData />} />

      {/* backend */}
      <Route path="/root" element={<Login />} />
      <Route path="/bimage" element={<Bimage />} />
      <Route path="/doctor" element={<DoctorSingup />} />
      <Route path="/nurse" element={<NurseSingup />} />
      <Route path="/message" element={<Message />} />
      <Route path="/bed" element={<BedDetails />} />
      <Route path="/bnotice" element={<BNotice />} />
      <Route path="/createnotice" element={<NoticeForm />} />
      <Route path="/login" element={<Dlogin />} />
      <Route path="/bedview" element={<BedView />} />
      <Route path="/logout" element={<Logout />} />
    </Routes>
  );
};

const Router = () => {
  const [state, dispatch] = useReducer(reducer, initailstate);
  const [states, dispatchs] = useReducer(dreducer, initailstates);
  return (
    <div>
      <UserContext.Provider value={{ state, dispatch, states, dispatchs }}>
        <RenderNav />
        <Routess />
      </UserContext.Provider>
      <Footer />
    </div>
  );
};

export default Router;

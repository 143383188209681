import React, { useContext } from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../views/router/Router";
const NoticeCreate = () => {
  const navigate = useNavigate();
  const { dispatchs } = useContext(UserContext);

  const [input, setInput] = useState({ email: "", password: "" });
  const { email, password } = input;

  const handleInput = (e) => {
    const name = e.target.name;
    setInput({ ...input, [name]: e.target.value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const res = await fetch("/doctor/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password }),
    });

    await res.json();

    if (res.status === 404) {
      console.log("Empty Field");
    } else if (res.status === 403) {
      window.alert("User not found");
    } else if (res.status === 401) {
      window.alert("Incorrect Password");
    } else if (res.status === 200) {
      dispatchs({ type: "DLOGIN", payload: true });
      navigate("/createnotice", { replace: true });
    }
    setInput({ email: "", password: "" });
  };
  return (
    <section className="h-full gradient-form bg-gradient-to-r from-indigo-100 via-purple-300 to-pink-100 md:h-screen">
      <div className="container py-12 px-6 h-full">
        <div className="flex justify-center items-center flex-wrap h-full g-6 text-gray-800">
          <div className="xl:w-10/12">
            <div className="block bg-white shadow-lg rounded-lg">
              <div className="lg:flex lg:flex-wrap g-0">
                <div className="lg:w-6/12 px-4 md:px-0">
                  <div className="md:p-12 md:mx-6">
                    <Link to="/root" className="cursor-context-menu">
                      <div className="text-center">
                        <img
                          className="mx-auto w-48"
                          src="https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/lotus.webp"
                          alt="logo"
                        />
                        <h4 className="text-xl font-semibold mt-1 mb-12 pb-1">
                          Cardiology SMCH
                        </h4>
                      </div>
                    </Link>
                    <form method="POST">
                      <p className="mb-4">Please login to your account</p>
                      <div className="mb-4">
                        <input
                          type="text"
                          className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                          id="exampleFormControlInput1"
                          placeholder="Email"
                          name="email"
                          onChange={handleInput}
                          value={email}
                        />
                      </div>
                      <div className="mb-4">
                        <input
                          type="password"
                          className="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                          id="exampleFormControlInput1"
                          placeholder="Password"
                          name="password"
                          onChange={handleInput}
                          value={password}
                        />
                      </div>
                      <div className="text-center pt-1 mb-12 pb-1">
                        <button
                          className="inline-block px-6 py-2.5 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out w-full mb-3 bg-blue-600"
                          type="button"
                          onClick={handleSubmit}
                        >
                          Log in
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="lg:w-6/12 flex items-center lg:rounded-r-lg rounded-b-lg lg:rounded-bl-none">
                  <div className=" px-4 py-6 md:p-12 md:mx-6">
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTlJzOBQbZRXWM2P_muND7-yKK8WgPlzPkkCw&usqp=CAU"
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NoticeCreate;

import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { RiDeleteBin5Fill } from "react-icons/ri";
import Loadings from "./Loading";
import axios from "axios";

const DoctorSingup = () => {
  const [error, setError] = useState(false);
  const inputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [handleFile, setHandleFile] = useState();
  const [doctorData, setDoctorData] = useState([]);
  const [userdata, setUserData] = useState();
  const navigate = useNavigate();

  const [inputHandle, setInputHandle] = useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
    cpassword: "",
    designation: "",
    place: "Satkhira Medical College Hospital, Satkhira",
  });
  const { name, email, mobile, password, cpassword, designation, place } =
    inputHandle;

  const handleInput = (e) => {
    const name = e.target.name;
    setInputHandle({ ...inputHandle, [name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("name", `Dr.  ${name}`);
    formData.append("email", email);
    formData.append("mobile", mobile);
    formData.append("place", place);
    formData.append("password", password);
    formData.append("cpassword", cpassword);
    formData.append("designation", designation);
    formData.append("file", handleFile);

    axios
      .post("/doctor", formData)
      .then(() => {
        DoctorData();
        setInputHandle({
          ...inputHandle,
          name: "",
          email: "",
          mobile: "",
          password: "",
          cpassword: "",
          designation: "",
        });
        inputRef.current.value = null;
        setError(false);
      })
      .catch((err) => {
        if (err.response.status === 404) {
          window.alert("Enter same password again");
        } else if (err.response.status === 403) {
          window.alert("You are already registered");
        } else {
          setError(true);
        }
      });
  };

  const Auth = async () => {
    try {
      const res = await fetch("/root", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      const data = await res.json();
      if (!res.status === 200) {
        console.log("response not working..");
      } else {
        setUserData(data);
      }
    } catch (err) {
      navigate("/root", { replace: true });
    }
  };

  useEffect(() => {
    Auth();
  }, []);

  const DoctorData = async () => {
    try {
      const res = await fetch("/doctor", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      if (res) {
        setIsLoading(false);
        setDoctorData(data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    DoctorData();
  }, []);

  // Delete Doctor data
  const handleDelete = async (_id) => {
    const res = await fetch("/doctor/" + `${_id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
    await res.json();
    if (res.status === 201) {
      window.alert("Delete Successfull");
      DoctorData();
    } else if (res.status === 400) {
      console.log("Delete not successfull");
    }
  };

  return (
    <>
      {isLoading && <Loadings />}
      <form
        method="post"
        onSubmit={handleSubmit}
        className="bg-gradient-to-r from-indigo-100 via-purple-300 to-pink-100 min-h-screen flex flex-col"
      >
        <div className="container max-w-sm mx-auto flex-1 flex flex-col items-center justify-center px-2">
          <div className="bg-transparent px-6 py-8 rounded shadow-xl text-black w-full ">
            <h1 className="mb-8 text-3xl text-center">Sign up</h1>
            <input
              type="text"
              className="block border border-grey-light w-full p-3 rounded mb-4"
              name="name"
              onChange={handleInput}
              value={name}
              placeholder="Dr. Full Name"
              required
            />

            <input
              type="text"
              className="block border border-grey-light w-full p-3 rounded mb-4"
              name="email"
              onChange={handleInput}
              value={email}
              placeholder="Email"
              required
            />
            <input
              type="text"
              className="block border border-grey-light w-full p-3 rounded mb-4"
              name="mobile"
              onChange={handleInput}
              value={mobile}
              placeholder="Mobile number (Optional)"
            />

            <select
              name="designation"
              className="block border border-grey-light w-full p-3 rounded mb-4"
              id="designation"
              onChange={handleInput}
              value={designation}
              required
            >
              <option className="text-red-500">Select Designation</option>
              <option value="Assestent Professor and HOD of Cardiology">
                Assestent Professor and HOD of Cardiology
              </option>
              <option value="Associate Professor and HOD of Cardiology">
                Associate Professor and HOD of Cardiology
              </option>
              <option value="Associate Professor">Associate Professor</option>
              <option value="Assestent Professor">Assestent Professor</option>
              <option value=" Senior Consultant">Senior Consultant</option>
              <option value="Junior Consultant">Junior Consultant</option>
            </select>
            <input
              type="password"
              className="block border border-grey-light w-full p-3 rounded mb-4"
              name="password"
              onChange={handleInput}
              value={password}
              placeholder="Password"
              required
            />
            <input
              type="password"
              className="block border border-grey-light w-full p-3 rounded mb-4"
              name="cpassword"
              onChange={handleInput}
              value={cpassword}
              placeholder="Confirm Password"
              required
            />
            <input
              type="file"
              ref={inputRef}
              className="block border border-grey-light w-full p-3 rounded mb-4"
              name="file"
              onChange={(e) => {
                setHandleFile(e.target.files[0]);
              }}
            />
            <div className={`${error ? "block" : "hidden"}`}>
              <h2 className="text-center font-semibold text-red-500 font-mono ">
                Only allow JPG,JPEG and PNG
              </h2>
            </div>

            <button
              type="submit"
              className="w-full text-center py-3 rounded bg-green-500 text-white hover:bg-green-600 focus:outline-none my-1"
            >
              Create Account
            </button>
          </div>
        </div>
      </form>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:p-10 p-4 bg-gradient-to-r from-indigo-100 via-purple-300 to-pink-100">
        {doctorData &&
          doctorData.map((data) => {
            const { _id, name, email, mobile, designation, place } = data;
            return (
              <div key={_id} className="p-5 shadow-xl flex justify-between">
                <div>
                  <h1 className="font-bold capitalize md:text-lg">{name}</h1>
                  <h1>{designation}</h1>
                  <h1>{mobile}</h1>
                  <h1>{email}</h1>
                  <h1>{place}</h1>
                </div>
                <button
                  className="font-bold text-2xl hover:text-red-700 transition-all duration-200"
                  onClick={() => {
                    handleDelete(_id);
                  }}
                >
                  <RiDeleteBin5Fill />
                </button>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default DoctorSingup;

import React from "react";
import { useState } from "react";

const ContactForm = () => {
  const [input, setInput] = useState({
    name: "",
    email: "",
    mobile: "",
    address: "",
    message: "",
  });
  const { name, email, mobile, message, address } = input;

  const handleInput = (e) => {
    const name = e.target.name;
    setInput({ ...input, [name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const res = await fetch("/message", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name, email, mobile, address, message }),
    });
    await res.json();
    if (res.status === 400) {
      setInput({ ...input, mobile: "" });
      window.alert("Mobile is a number");
    } else if (res.status === 201) {
      window.alert("Message sent successfull");
      setInput({ name: "", email: "", mobile: "", message: "", address: "" });
    }
  };

  return (
    <div className="bg-gray-50 min-h-screen">
      <div className="flex flex-col justify-center items-center min-h-screen">
        <form
          method="POST"
          className="max-w-4xl bg-white p-10 shadow-md"
          onSubmit={handleSubmit}
        >
          <div className="flex space-x-10">
            <input
              type="text"
              placeholder="Name"
              required
              name="name"
              onChange={handleInput}
              value={name}
              className="border-b-2 border-indigo-300 outline-none bg-white/90 focus:border-red-300 py-2"
            />
            <input
              type="email"
              placeholder="Email"
              name="email"
              required
              onChange={handleInput}
              value={email}
              className="border-b-2 border-indigo-300 outline-none bg-white/90 focus:border-red-300 py-2"
            />
          </div>
          <div className="flex space-x-10">
            <input
              type="text"
              required
              placeholder="Mobile no (01700)"
              name="mobile"
              onChange={handleInput}
              value={mobile}
              className="border-b-2 border-indigo-300 outline-none bg-white/90 focus:border-red-300 py-2"
            />
            <input
              type="text"
              required
              placeholder="Present Address"
              name="address"
              onChange={handleInput}
              value={address}
              className="border-b-2 border-indigo-300 outline-none bg-white/90 focus:border-red-300 py-2"
            />
          </div>
          <div>
            <textarea
              name="message"
              required
              id="message"
              onChange={handleInput}
              value={message}
              className="w-full h-32 outline-none ring-1 ring-indigo-400 my-5 p-2 focus:ring-red-400"
              placeholder="Enter message"
            ></textarea>
          </div>
          <div>
            <button className="w-full py-3 bg-indigo-700 my-3 rounded shadow text-white font-semibold hover:bg-indigo-500 transition duration-200">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;

import React, { useEffect, useState } from "react";
import Loadings from "../Loading";

const Employs = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [nurseData, setNurseData] = useState([]);
  //GET Nurse Data
  const GetNurseData = async () => {
    const res = await fetch("/nurse/nurse", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const data = await res.json();
    if (res.status === 200) {
      setNurseData(data);
      setIsLoading(false);
    } else if (res.status === 404) {
      console.log("Server error");
    }
  };
  useEffect(() => {
    GetNurseData();
  }, []);

  return (
    <>
      {isLoading && <Loadings />}
      <div className="grid grid-rows-1 sm:grid-cols-2 md:grid-cols-2 p-5 lg:px-44 lg:p-20 gap-10">
        {nurseData &&
          nurseData.map((employs) => {
            const { _id, name, place, designation, filePath } = employs;
            return (
              <div key={_id}>
                <div className="flex  flex-col md:flex-row justify-center items-center text-center rounded-md shadow p-4 ring-gray-100 ring-1">
                  <img
                    className="w-48 h-48 rounded-full pb-3"
                    src={filePath}
                    alt={name}
                  />
                  <div>
                    <h1 className="font-bold text-lg capitalize">{name}</h1>
                    <h2 className="text-gray-600">{designation}</h2>
                    <p className="text-gray-600 mb-4">{place}</p>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default Employs;

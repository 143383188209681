import React, { useEffect } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../views/router/Router";

const Logout = () => {
  const navigate = useNavigate();
  const { dispatch } = useContext(UserContext);
  useEffect(() => {
    fetch("/logout", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then((res) => {
        dispatch({ type: "USER", payload: false });
        navigate("/login", { replace: true });
        if (!res.ok) {
          throw Error("Logout not working");
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return <div>{console.log("Logout successfull")}</div>;
};

export default Logout;

import React from "react";

import Slider from "../Slider/Slider";
import Doctor from "./Doctor";
import Home from "./Home";
import Data from "../../Data/Faq.json";
import Faq from "./Faq";
import ContactForm from "../../views/ContactForm";

const Homepages = () => {
  return (
    <div>
      <Slider />
      {/* <Home /> */}
      <Doctor />
      <div className="bg-white shadow  text-black">
        <h1 className="text-center font-extrabold text-xl m-4 p-3">FAQ</h1>
        <div className="grid md:grid-cols-2 grid-cols-1">
          {Data && Data.map((datas) => <Faq key={datas.id} {...datas} />)}
        </div>
      </div>
      <ContactForm />
    </div>
  );
};

export default Homepages;

import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function Bimage() {
  const [handleFile, setHandleFile] = useState();
  const inputRef = useRef();
  const [error, setError] = useState(false);
  const navigate = useNavigate();

  const [images, setImage] = useState();

  const Auth = async () => {
    try {
      const res = await fetch("/root", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
      });
      await res.json();
      if (!res.status === 200) {
        console.log("response not working..");
      } else {
        console.log("Authorigetd");
      }
    } catch (err) {
      navigate("/root", { replace: true });
    }
  };

  useEffect(() => {
    Auth();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", handleFile);

    axios
      .post("/gallery", formData)
      .then((res) => {
        if (res.status === 201) {
          window.alert("File Upload Complete");
          getImage();
          inputRef.current.value = null;
          setError(false);
        } else if (res.status === 400) {
          setError("File Error ! Check your file");
        }
      })
      .catch((err) => {
        setError(true);
      });
  };

  // Delete Notice
  const handleDelete = async (_id) => {
    axios
      .delete("/gallery" + `/${_id}`)
      .then(() => {
        getImage();
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const getImage = () => {
    axios
      .get("/gallery")
      .then((res) => {
        setImage(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  useEffect(() => {
    getImage();
  }, []);

  return (
    <>
      <form
        method="POST"
        encType="multipart/form-data"
        onSubmit={handleSubmit}
        className="m-5 md:m-10"
      >
        <div className="text-center font-bold text-gray-700 p-4 md:text-2xl text-xl">
          <h2>Gallery Image Upload</h2>
        </div>
        <div className="md:mx-20 mt-5 mx-5">
          <label
            class="block mb-2 text-sm  text-gray-900 font-mono font-semibold md:font-bold"
            for="default_size"
          >
            Choose image file
          </label>
          <input
            class="block mb-5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 cursor-pointer p-4"
            id="default_size"
            ref={inputRef}
            required
            type="file"
            onChange={(e) => {
              setHandleFile(e.target.files[0]);
            }}
          />
          <div className={`${error ? "block" : "hidden"}`}>
            <h2 className="text-center text-red-600 p-2">
              Only allow JPG, JPEG and PNG
            </h2>
          </div>
        </div>
        <div className="flex justify-center items-center">
          <button
            type="submit"
            className="text-white bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 rounded-lg text-sm px-5 py-2.5 text-center mr-2 mb-2 font-semibold"
          >
            Upload
          </button>
        </div>
      </form>
      <div className="grid md:grid-cols-3 grid-cols-1 m-4 md:10 gap-5">
        {images &&
          images.map((data) => {
            const { _id, filePath } = data;
            return (
              <div key={_id}>
                <img src={filePath} alt="galleryPhoto" className="w-full" />
                <button
                  onClick={() => {
                    handleDelete(_id);
                  }}
                  className="w-full p-3 bg-red-500 text-white font-bold"
                >
                  Delete
                </button>
              </div>
            );
          })}
      </div>
    </>
  );
}

export default Bimage;

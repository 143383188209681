import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Loadings from "./Loading";

const BedView = () => {
  const navigate = useNavigate();
  const [bedData, setBedData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  //Authoriged function
  const Auth = async () => {
    try {
      const res = await fetch("/doctor/ddata", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (res.status !== 200) {
        navigate("/login", { replace: true });
      }
    } catch (err) {
      console.log(err);
    }
  };

  //Bed data get
  const BedData = async () => {
    try {
      const res = await fetch("/bed/bed", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      if (res.status === 404) {
        console.log("Data not found | server error");
      } else if (res.status === 200) {
        setBedData(data);
        setIsLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    Auth();
    BedData();
  }, []);

  // Delete Bed Data

  return (
    <>
      {isLoading && <Loadings />}
      <div className="md:p-10 bg-gradient-to-r from-indigo-100 via-purple-300 to-pink-100 ">
        <div className="flex flex-col-reverse">
          {bedData &&
            bedData.map((data) => {
              const { _id, ccutb, ccufb, pccutb, pccufb, date } = data;
              return (
                <div className="my-5 mx-2 shadow-md bg-white" key={_id}>
                  <div className="grid grid-cols-1 text-gray-500  text-center divide-x-2  p-5 ring-1 ring-gray-300 md:font-bold  font-medium text-xs md:text-base">
                    {date}
                  </div>
                  <div className="grid grid-cols-4 items-center justify-center text-center divide-x-2  p-5 ring-1 ring-gray-300 md:font-bold font-medium text-xs md:text-base bg-white">
                    <div>CCU Bed</div>
                    <div>Free Bed</div>
                    <div>PCCU Total</div>
                    <div>Free Bed</div>
                  </div>
                  <div className="grid grid-cols-4 items-center justify-center text-center divide-x-2  p-5 ring-1 ring-gray-300 md:font-normal  font-medium text-xs md:text-base">
                    <div>{ccutb}</div>
                    <div>{ccufb}</div>
                    <div>{pccutb}</div>
                    <div>{pccufb}</div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default BedView;

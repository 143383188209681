import React, { useEffect, useState } from "react";
import Loadings from "./Loading";
import { Link, Route } from "react-router-dom";

const Notice = () => {
  const [notice, setNotice] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const GetNoticeData = async () => {
    const res = await fetch("/notice/publicnotice", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    });
    const data = await res.json();
    if (res.status === 404) {
      console.log("data not get");
    } else {
      setNotice(data);
      setIsLoading(false);
      return data;
    }
  };
  useEffect(() => {
    GetNoticeData();
  }, []);

  return (
    <>
      {isLoading && <Loadings />}
      <div className="flex flex-col-reverse bg-gradient-to-r from-indigo-100 via-purple-300 to-pink-100 md:p-10 p-3">
        {notice &&
          notice.map((data) => {
            const {
              _id,
              name,
              designation,
              message,
              date,
              fileName,
              filePath,
            } = data;
            return (
              <div key={_id}>
                <div className="bg-white p-5 m-5 shadow-md ring-1 ring-gray-300 divide-y-2 space-y-4 flex flex-col justify-center">
                  <div className="flex justify-between">
                    <div>
                      <h2 className="uppercase text-gray-800 font-bold">
                        {name}
                      </h2>
                      <h2 className="font-light text-gray-500">
                        {designation}
                      </h2>
                      <h2 className="font-light text-gray-500">{date}</h2>
                    </div>
                    <div>
                      <a
                        className="px-5 md:px-10 text-white font-bold py-3 bg-orange-600 hover:bg-red-700 transition duration-200 rounded shadow inline-block"
                        download
                        href={`${filePath}`}
                      >
                        PDF
                      </a>
                    </div>
                  </div>
                  <h2 className="w-full  overflow-y-auto py-5 font-bold">
                    {message}
                  </h2>
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default Notice;

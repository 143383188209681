import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";

const Doctor = () => {
  const [doctorData, setDoctorData] = useState([]);
  useEffect(() => {
    axios
      .get("/doctor/doctorpublic")
      .then((res) => {
        setDoctorData(res.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  return (
    <div className="grid grid-rows-1 sm:grid-cols-2 md:grid-cols-2 p-5 lg:px-44 lg:p-20 gap-10 bg-white">
      {doctorData &&
        doctorData.map((DTdata) => {
          const { _id, name, filePath, place, designation } = DTdata;
          return (
            <div key={_id}>
              <div className="flex flex-col justify-center items-center text-center rounded-md shadow p-4 ring-gray-200 ring-1">
                <img
                  className="w-48 h-48 rounded-full pb-3"
                  src={filePath}
                  alt={name}
                />
                <div>
                  <h1 className="font-bold text-lg">{name}</h1>
                  <h2 className="text-gray-600">{designation}</h2>
                  <p className="text-gray-600 mb-4">{place}</p>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Doctor;

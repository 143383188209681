import React from "react";
import loading from "../image/Loading.gif";

const Loading = () => {
  return (
    // <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
    <div className="flex h-screen justify-center items-center">
      <img src={loading} alt="loading photos" />
    </div>
  );
};

export default Loading;

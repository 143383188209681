import React from "react";
import { useLocation } from "react-router-dom";

const Employ = () => {
  const location = useLocation();
  return (
    <div>
      <div className="flex md:flex-row flex-col justify-evenly items-center p-5 md:mx-28 sm:text-left text-center sm:space-x-12 lg:space-x-0">
        <div className="mt-3">
          <img
            className="w-40 h-40 sm:w-64 sm:h-64 p-1 shadow"
            src={location.state.nurses}
            alt=""
          />
        </div>
        <div className="font-semibold text-sm sm:text-base">
          <h3 className="py-1 capitalize sm:py-3 text-lg text-semibold sm:text-xl sm:font-bold cap">
            {location.state.name}
          </h3>
          <div className="text-gray-500">{location.state.designation}</div>
          <div className="text-gray-500">{location.state.place}</div>
          <div className="text-gray-500">{location.state.digree}</div>
        </div>
      </div>
      <div>
        <div className="ring-1 ring-gray-200 p-5 m-4 h-44 shadow text-justify">
          {location.state.others}
        </div>
      </div>
    </div>
  );
};

export default Employ;

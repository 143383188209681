import React from "react";

const Error = () => {
  return (
    <div className="flex min-h-screen justify-center items-center">
      <div className="font-mono text-red-500 flex text-center justify-center top-2/4">
        404 Page not found..
      </div>
    </div>
  );
};

export default Error;

import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const NoticeForm = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    designation: "",
    message: "",
  });
  const { name, message, email, designation } = userData;

  const GETDATA = async () => {
    try {
      const res = await fetch("/doctor/ddata", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await res.json();
      if (res.status === 200) {
        setUserData(data);
      }
    } catch (err) {
      console.log(err);
      navigate("/login", { replace: true });
    }
  };

  useEffect(() => {
    GETDATA();
  }, []);

  const handleInput = (e) => {
    const name = e.target.name;
    setUserData({ ...userData, [name]: e.target.value });
  };
  const [handleFile, setHandleFile] = useState();
  const fileRaf = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("message", message);
    formData.append("designation", designation);
    formData.append("file", handleFile);

    axios
      .post("/notice", formData)
      .then((res) => {
        window.alert("Create successfull");
        fileRaf.current.value = null;
        setError(false);
        setUserData({ ...userData, message: "" });
        navigate("/notice", { replace: true });
      })
      .catch((err) => {
        console.log(err.message);
        setError(true);
      });
  };

  return (
    <div className="bg-gradient-to-r from-indigo-100 via-purple-300 to-pink-100 min-h-screen">
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <form
          method="POST"
          className="max-w-4xl bg-white p-10"
          onSubmit={handleSubmit}
        >
          <div className="flex md:space-x-5 flex-col md:flex-row">
            <input
              type="text"
              placeholder="Name"
              name="name"
              onChange={handleInput}
              value={userData.name}
              className="border-b-2 border-indigo-300 outline-none bg-white/90 focus:border-red-300 py-2"
            />
            <input
              type="email"
              placeholder="Email"
              name="email"
              onChange={handleInput}
              value={userData.email}
              className="border-b-2 border-indigo-300 outline-none bg-white/90 focus:border-red-300 py-2"
            />
            <input
              type="text"
              placeholder="Deginaction"
              name="designation"
              onChange={handleInput}
              value={userData.designation}
              className="border-b-2 border-indigo-300 outline-none bg-white/90 focus:border-red-300 py-2"
            />
          </div>
          <div>
            <textarea
              name="message"
              id="message"
              onChange={handleInput}
              required
              value={userData.message}
              className="w-full h-32 outline-none ring-1 ring-indigo-400 my-5 p-2 focus:ring-red-400"
              placeholder="Enter Notice text"
            ></textarea>
          </div>
          <div>
            <input
              type="file"
              name="file"
              id="file"
              required
              ref={fileRaf}
              onChange={(e) => {
                setHandleFile(e.target.files[0]);
              }}
            />
            <div className={`${error ? "block" : "hidden"}`}>
              <h1 className="text-red-600 p-3 font-mono font-semibold text-center">
                Only PDF Upload
              </h1>
            </div>
          </div>
          <div>
            <button className="w-full py-3 bg-indigo-700 my-3 rounded shadow text-white font-semibold hover:bg-indigo-500 transition duration-200">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default NoticeForm;

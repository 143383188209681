import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "./router/Router";
import { useContext } from "react";

const Login = () => {
  const { dispatch } = useContext(UserContext);

  const navigate = useNavigate();
  const [input, setInput] = useState({ username: "", password: "" });
  const { username, password } = input;

  const handleInput = (e) => {
    const name = e.target.name;
    setInput({ ...input, [name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const res = await fetch("/root/login", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: JSON.stringify({ username, password }),
    });

    await res.json();

    if (res.status === 403) {
      window.alert("wrong user");
    } else if (res.status === 404) {
      window.alert("Incorrect password");
    } else if (res.status === 200) {
      navigate("/message", { replace: true });
      dispatch({ type: "USER", payload: true });
    }

    setInput({ username: "", password: "" });
  };

  return (
    <div className="font-mono bg-gray-50">
      <form
        className="flex justify-center items-center h-screen"
        onSubmit={handleSubmit}
      >
        <div className="bg-white shadow rounded-lg p-4 md:p-7 md:w-1/3 justify-center items-center flex flex-col space-y-3">
          <div className="">
            <input
              type="text"
              className="outline-none py-3 border-b-2 border-gray-300 focus:border-green-400 bg-white w-80"
              placeholder="username"
              name="username"
              required
              value={username}
              onChange={handleInput}
            />
          </div>
          <div>
            <input
              type="password"
              className="outline-none py-3 border-b-2 border-gray-300 focus:border-green-400 bg-white w-80"
              placeholder="password"
              name="password"
              required
              value={password}
              onChange={handleInput}
            />
          </div>
          <div>
            <button className="px-5 py-2 bg-indigo-500 text-white rounded-3xl shadow mt-3 font-semibold hover:bg-indigo-600 tranistion duration-200">
              Login
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Login;

import React, { useContext, useState } from "react";
import { UserContext } from "./Router";
import { NavLink } from "react-router-dom";
import { GoEyeClosed } from "react-icons/go";
import { HiMenu } from "react-icons/hi";
import logo from "../../image/dashboard.jpg";

const RenderNav = () => {
  const [navbar, setNavber] = useState(false);
  const { state, states, dispatchs } = useContext(UserContext);

  if (state) {
    return (
      <nav className="bg-gray-100 shadow-md text-gray-600 flex justify-between  items-center w-full text-sm font-bold md:text-base ">
        <NavLink to="/">
          <img
            className="w-12 h-12 md:w-16 md:h-16 p-2 rounded-full"
            src={logo}
            alt=""
          />
        </NavLink>

        <div className="">
          <ul
            className={`absolute md:static z-50 w-full transition duration-300 left-0 ${
              navbar ? "top-[50px]  scale-100" : "scale-0 md:scale-100"
            }`}
          >
            <li className="flex flex-col md:flex-row  top-36 md:top-0  bg-gray-100 md:bg-origin-content md:show">
              <NavLink
                onClick={() => {
                  setNavber(!navbar);
                }}
                className="hover:text-orange-400 px-4 py-3 "
                to="/doctor"
              >
                Doctor Add
              </NavLink>

              <NavLink
                onClick={() => {
                  setNavber(!navbar);
                }}
                className="hover:text-orange-400 px-4 py-3 "
                to="/nurse"
              >
                Nurse Add
              </NavLink>

              <NavLink
                onClick={() => {
                  setNavber(!navbar);
                }}
                className="hover:text-orange-400 px-4 py-3 "
                to="/message"
              >
                Message
              </NavLink>

              <NavLink
                onClick={() => {
                  setNavber(!navbar);
                }}
                className="hover:text-orange-400 px-4 py-3 "
                to="/bed"
              >
                Bed Add
              </NavLink>

              <NavLink
                onClick={() => {
                  setNavber(!navbar);
                }}
                className="hover:text-orange-400 px-4 py-3 "
                to="/bnotice"
              >
                B-Notice
              </NavLink>
              <NavLink
                onClick={() => {
                  setNavber(!navbar);
                }}
                className="hover:text-orange-400 px-4 py-3 "
                to="/bimage"
              >
                Image Upload
              </NavLink>

              {/* stop here */}

              <NavLink
                onClick={() => {
                  setNavber(!navbar);
                }}
                className="hover:text-orange-400 px-4 py-3 m-3 md:m-0 bg-indigo-600 rounded-xl hover:bg-indigo-800 transition duration-200 text-center items-center text-white"
                to="/logout"
              >
                Logout
              </NavLink>
            </li>
          </ul>
        </div>
        <div
          className="text-2xl md:hidden cursor-pointer hover:text-orange-500 m-4"
          onClick={() => {
            setNavber(!navbar);
          }}
        >
          {navbar ? <GoEyeClosed /> : <HiMenu />}
        </div>
      </nav>
    );
  } else {
    return (
      <nav className="bg-gray-100 shadow-md text-gray-600 flex justify-between items-center w-full text-sm font-bold md:text-base ">
        <NavLink to="/">
          <img
            className="w-12 h-12 md:w-16 md:h-16 p-2 rounded-full"
            src={logo}
            alt=""
          />
        </NavLink>

        <div className="">
          <ul
            className={`absolute md:static z-50 w-full transition duration-300 left-0 ${
              navbar ? "top-[50px]  scale-100" : "scale-0 md:scale-100"
            }`}
          >
            <li className="flex flex-col md:flex-row  top-36 md:top-0  bg-gray-100 md:bg-origin-content md:show">
              <NavLink
                onClick={() => {
                  setNavber(!navbar);
                }}
                className="hover:text-orange-400 px-4 py-3 my-3 transition duration-300"
                to="/"
              >
                Home
              </NavLink>
              <NavLink
                onClick={() => {
                  setNavber(!navbar);
                }}
                className="hover:text-orange-400 px-4 py-3 my-3 transition duration-300"
                to="/about"
              >
                About
              </NavLink>

              <NavLink
                onClick={() => {
                  setNavber(!navbar);
                }}
                className="hover:text-orange-400 px-4 py-3 my-3 transition duration-300"
                to="/HomeEmploys"
              >
                Employs
              </NavLink>
              <NavLink
                onClick={() => {
                  setNavber(!navbar);
                }}
                className="hover:text-orange-400 px-4 py-3 my-3"
                to="/contact"
              >
                Contact
              </NavLink>

              <NavLink
                onClick={() => {
                  setNavber(!navbar);
                }}
                className={`hover:text-orange-400 px-4 py-3 my-3 ${
                  states ? "show" : "hidden"
                }`}
                to="/bedview"
              >
                Bed
              </NavLink>

              <NavLink
                onClick={() => {
                  setNavber(!navbar);
                }}
                className={`hover:text-orange-400 px-4 py-3 my-3 ${
                  states ? "show" : "hidden"
                }`}
                to="/createnotice"
              >
                Create Notice
              </NavLink>
              <NavLink
                onClick={() => {
                  setNavber(!navbar);
                }}
                className="hover:text-orange-400 px-4 py-3 my-3"
                to="/notice"
              >
                Notice
              </NavLink>

              {states ? (
                <NavLink
                  onClick={() => {
                    setNavber(!navbar);
                    dispatchs({ type: "DLOGIN", payload: false });
                  }}
                  className="hover:text-orange-400 px-4 py-3 m-3 bg-indigo-600 rounded-xl hover:bg-indigo-800 transition duration-200 text-center items-center text-white"
                  to="/logout"
                >
                  Logout
                </NavLink>
              ) : (
                <NavLink
                  onClick={() => {
                    setNavber(!navbar);
                  }}
                  className={`hover:text-orange-400 px-4 py-3 my-3`}
                  to="/login"
                >
                  Login
                </NavLink>
              )}

              {/* stop here */}
              <NavLink
                onClick={() => {
                  setNavber(!navbar);
                }}
                className="hover:text-orange-400 px-4 py-3 m-3 md:mx-3 bg-indigo-600 rounded-xl hover:bg-indigo-800 transition duration-200 text-center items-center text-white"
                to="/gallery"
              >
                Gallery
              </NavLink>
            </li>
          </ul>
        </div>
        <div
          className="text-2xl md:hidden cursor-pointer hover:text-orange-500 m-4"
          onClick={() => {
            setNavber(!navbar);
          }}
        >
          {navbar ? <GoEyeClosed /> : <HiMenu />}
        </div>
      </nav>
    );
  }
};

export default RenderNav;

import React, { useState } from "react";
import { BsArrowDownCircle } from "react-icons/bs";

const Faq = ({ id, question, answer }) => {
  const [toggle, setToggle] = useState(false);

  return (
    <div
      key={id}
      className="bg-gray-50 ring-1 ring-gray-300 text-black rounded shadow m-4 p-4 cursor-pointer"
    >
      <div
        className="font-bold flex justify-between items-cemter"
        onClick={() => {
          setToggle(!toggle);
        }}
      >
        {question}
        <div className="font-extrabold">
          {toggle ? (
            <BsArrowDownCircle className="rotate-180 text-orange-400 transition duration-1000" />
          ) : (
            <BsArrowDownCircle className="text-green-400 transition duration-1000" />
          )}
        </div>
      </div>
      {toggle && (
        <div className="ring-1 ring-gray-300 my-3 p-3 shadow rounded ">
          {answer}
        </div>
      )}
    </div>
  );
};

export default Faq;
